import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import { Helmet } from "react-helmet"
import SEO from "../../components/seo"
import Layout from "../../modules/layout"
import Breadcrumbs from "../../modules/breadcrumbs"
import Post from "../../components/post"
import BlockContent from "../../components/page-builder/richText"

import styles from "./news.module.scss"

export const query = graphql`
  query($slug: String) {
    sanityNewsroom(slug: { current: { eq: $slug } }) {
      title
      publishedAt(formatString: "MMM D, YYYY")
      seo {
        metaTitle
        canonical
        description
        noIndex
      }
      _rawBody
    }
  }
`

const NewsTemplate = props => {
  const { data, location } = props
  const post = data && data.sanityNewsroom
  const title = post.seo && post.seo.metaTitle ? post.seo.metaTitle : post.title
  const description =
    post.seo && post.seo.description ? post.seo.description : null
  const image =
    post.seo &&
    post.seo.openGraphImage &&
    post.seo.openGraphImage.asset !== null
      ? post.seo.openGraphImage.asset.url
      : null
  return (
    <>
      <Layout>
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
        <SEO title={title} description={description} image={image} />
        <Breadcrumbs location={location}>
          <Link to="/">Home</Link>
          <Link to="/newsroom/">Newsroom</Link>
          <p>{post.title}</p>
        </Breadcrumbs>
        <section className={styles.newsHeader}>
          <div className="grid">
            <div className="gridItem large-8 large-offset-2 medium-10 medium-offset-1">
              <h1 className={styles.newsTitle}>{post.title}</h1>
            </div>
          </div>
        </section>
        <section className="grid">
          <div className="gridItem gridItemCenter large-11">
            <Post>
              <BlockContent blocks={post._rawBody} />
            </Post>
          </div>
        </section>
      </Layout>
    </>
  )
}

export default NewsTemplate
